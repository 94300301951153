<template>
  <div class="footer">
    <div class="flex-box m1440">
      <a href="javascript:;" class="logo-w"></a>
      <div class="flex-grow-1">
        <div class="flex-box flex-end links pb20 flex-wrap">
          <router-link to="/">{{ $t('home') }}</router-link>
          <router-link to="/market">{{ $t('market') }}</router-link>
          <router-link to="/map">{{ $t('map') }}</router-link>
          <router-link to="/card">{{ $t('card') }}</router-link>
          <router-link to="/basic_info">{{ $t('personal') }}</router-link>
          <!-- <router-link to="/">FDISCOVERY</router-link>
          <router-link to="/">BLOG</router-link>
          <router-link to="/">FQA</router-link> -->
    
            <router-link v-if="!user" to="/login?tabindex=1">{{ $t('login') }}</router-link>
            <router-link v-if="!user" to="/login?tabindex=0">{{ $t('signup') }}</router-link>
          
         
        </div>
        <div class="flex-box flex-end">
          <!--
          <a href="javascript:;" class="police"></a>
          <router-link to="/terms?type=web" class="pl30">《Website Agreement》</router-link>
          <router-link to="/terms?type=privacy" class="pl30">《Privacy Agreement》</router-link>
          -->
          <a href="javascript:;" class="pl30">Copyright © 2023 SMA </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cookie from 'vue-cookies'
export default {
  name: "Footer",
  data() {
    return {
      user:''
    };
  },
  created() {
    this.getuser()
   },
  watch: {},
  destroyed() { },
  mounted() { },
  methods: {
    getuser(){
      if(cookie.get('sma_token')){
        this.$api.apiUserInfo({}).then(ret=>{
          if(ret.code==1){
            this.user = ret.data.userinfo;
          }
        })
      }
    }
  },
};
</script>
